import React from 'react';
import { Card, CardContent, Typography, Button, useTheme } from '@mui/material';

const PrepaymentInfo = ({ onClose }) => {
  const theme = useTheme(); // Access the theme

  return (
    <Card variant="outlined" style={{ margin: '20px', padding: '20px', borderColor: '#2196F3' }}>
      <CardContent style={{ textAlign: 'center' }}>
        <Typography variant="body1">
          За да потвърдите своята резервация, е необходимо да предплатите 50% от крайната цена в срок от 3 работни дни.
        </Typography>

        <Typography variant="body1" style={{ marginTop: '16px' }}>
          Ако престоят е за една нощувка, молим да заплатите 100% от сумата.
        </Typography>

        <Typography variant="body1" style={{ marginTop: '16px' }}>
          Тази стъпка гарантира вашето посещение и ви осигурява спокойствие, знаейки, че всичко е подредено.
        </Typography>

        

        <Button variant="contained" color="primary" onClick={onClose} style={{ marginTop: '25px' }}>
          Как да платя ?
        </Button>
        <Typography variant="body1" style={{ marginTop: '25px', color: theme.palette.warning.main }}>
          Благодарим Ви, че избрахте Комплекс Боже Име !
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PrepaymentInfo;
