// lightTheme.js
import { createTheme } from '@mui/material/styles';
import { lightPalette } from './palette';
import { componentsOverrides } from './componentsOverrides'; // Import component overrides

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: lightPalette.primaryMain,
      dark: lightPalette.primaryDark, // Ensure dark color is defined
      light: lightPalette.primaryLight, // Ensure light color is defined
      contrastText: lightPalette.primaryContrastText || '#ffffff', // Use contrast text from palette or fallback to white
    },
    secondary: {
      main: lightPalette.secondaryMain,
    },
    background: {
      default: lightPalette.backgroundDefault,
      paper: lightPalette.backgroundPaper,
    },
    text: {
      primary: lightPalette.textPrimary,
    },
  },
  components: componentsOverrides, // Apply the component overrides for MuiButton and others
});

export default lightTheme;
