import React from 'react';
import { Box, useTheme } from '@mui/material';

function Header() {
  const theme = useTheme(); // Get the current theme

  // Check if the theme is in dark mode
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      component="header"
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 20px',
        boxShadow: 1,
        marginBottom: 2,
        backgroundColor: 'background.paper',
      }}
    >
      {/* Switch between dark and light logos */}
      <img
        src={!isDarkMode ? "/logo_dark.svg" : "/logo.svg"} // Choose based on theme
        alt="Logo"
        style={{
          height: '45px',
        }}
      />

      {/* Switch between dark and light text logos */}
      <img
        src={!isDarkMode ? "/text_logo_dark.svg" : "/text_logo.svg"} // Choose based on theme
        alt="Text Logo"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',

        }}
      />
    </Box>
  );
}

export default Header;
