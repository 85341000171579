import * as React from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber'; // Warning icon
import { styled } from '@mui/material/styles'; // Import styled from MUI

// Function to get the dynamic date range
const getDynamicDates = () => {
  const today = new Date();
  const arrivalDate = new Date(today);
  arrivalDate.setDate(today.getDate() + 10); // 10 days after today

  const departureDate = new Date(arrivalDate);
  departureDate.setDate(arrivalDate.getDate() + 5); // Example: 5 days stay

  // Format dates as dd.mm.yyyy
  const arrivalStr = arrivalDate.toLocaleDateString('bg-BG', { day: '2-digit', month: '2-digit', year: 'numeric' });
  const departureStr = departureDate.toLocaleDateString('bg-BG', { day: '2-digit', month: '2-digit', year: 'numeric' });

  return { arrivalStr, departureStr };
};

// Styled TextField to override label and input colors
const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    color: theme.palette.warning.main, // Set the label color to theme warning color
  },
  '& .Mui-focused': {
    color: theme.palette.warning.main, // Set the focused label color to theme warning color
    borderWidth: '1px',
    
  },
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.warning.main, // Set the border color to theme warning color
    borderWidth: '1px',

    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.warning.main,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.warning.main,
    borderWidth: '1px',

    },
  },
  '& .MuiInputBase-input': {
    color: theme.palette.warning.main, // Set the text color inside the input
    fontSize: '0.8em',


  },
}));

function ReasonReminder({ name = 'ОСНОВАНИЕ ЗА ПЛАЩАНЕ' }) {
  const { arrivalStr, departureStr } = getDynamicDates(); // Get the dynamic dates

  const content = `Въведете името на резервацията и датите на пристигане и напускане (например „Резервация Иван Иванов, ${arrivalStr} - ${departureStr}“).`;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginBottom: 0, marginTop: 3, borderRadius: 1 }}>
      <CssTextField
        fullWidth
        variant="outlined"
        label={name}
        value={content}
        multiline
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <WarningAmberIcon color="warning" /> {/* Use color prop to match theme */}
            </InputAdornment>
          ),
        }}
        focused
      />
    </Box>
  );
}

export default ReasonReminder;
