// BankDetails.js
import * as React from 'react';
import { Card, CardContent, Typography, Box, Divider, Button } from '@mui/material';
import CopyableField from '../CopyableField/CopyableField';
import InfoModal from '../InfoModal/InfoModal'; // Corrected import path for InfoModal
import ReasonReminder from '../ReasonReminder/ReasonReminder';

function BankDetails() {
  const [open, setOpen] = React.useState(false);

  // Function to handle modal open
  const handleOpen = () => setOpen(true);

  // Function to handle modal close
  const handleClose = () => setOpen(false);

  return (
    <Card sx={{width: '90%', maxWidth: 800, margin: '40px auto', padding: '0px', borderRadius: 2 }}>
      <CardContent>
     
<p>
Използвайте мобилното си банкиране или посетете клон на вашата банка за извършване на плащането.
</p>
        <Divider sx={{ marginTop: 1, marginBottom: 5, backgroundColor: 'primary.main', height: 2  }} />
        

        <CopyableField name="IBAN" content="BG57BPBI79221018759903" />

        <CopyableField name="БЕНЕФИЦИЕНТ" content="Ванита-2 ЕООД" />

        <CopyableField name="BIC" content="BPBIBGSF" />

        <ReasonReminder />

        <Divider sx={{ marginY: 2 }} />

        {/* Button on the Left and Logos on the Right */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 0 }}>
          {/* Learn More Button on the Left */}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpen}
            sx={{ fontSize: '0.6rem' }} // Adjust the font size of the button text
          >
            Как да направя превод ?
          </Button>

          {/* Logos on the Right */}
          <Box sx={{ display: 'flex', gap: 1 }}>
            <img
              src="/bank_logo.svg"
              alt="Bank Logo"
              style={{ height: '30px', width: '30px', objectFit: 'cover' }}
            />
            <img
              src="/easypay_logo.svg"
              alt="EasyPay Logo"
              style={{ height: '30px', width: '30px', objectFit: 'cover' }}
            />
          </Box>
        </Box>
      </CardContent>

      {/* InfoModal Component */}
      <InfoModal open={open} handleClose={handleClose} />
    </Card>
  );
}

export default BankDetails;
