import React, { useState } from 'react';
import { Box, TextField, IconButton, InputAdornment } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function CopyableField({ name = 'Joining Link', content = 'https://fileshare.io/000-000-000' }) {
  const [copied, setCopied] = useState(false);
  const [displayText, setDisplayText] = useState(content);
  const [textColor, setTextColor] = useState('text.primary'); // Default color
  const [borderColor, setBorderColor] = useState('primary.main'); // Default border color

  const handleCopy = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(content).then(() => {
        setCopied(true);
        setDisplayText('КОПИРАНО В КЛИПБОРДА');
        setTextColor('success.main'); // Change text color to green
        setBorderColor('success.main'); // Change border color to green
        setTimeout(() => {
          setDisplayText(content);
          setTextColor('text.primary'); // Revert text color
          setBorderColor('primary.main'); // Revert border color
          setCopied(false);
        }, 3000);
      }).catch(() => {
        fallbackCopyTextToClipboard(content);
      });
    } else {
      fallbackCopyTextToClipboard(content);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.top = '-9999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      setCopied(true);
      setDisplayText('Копирано');
      setTextColor('success.main'); // Change text color to green
      setBorderColor('success.main'); // Change border color to green
      setTimeout(() => {
        setDisplayText(content);
        setTextColor('text.primary'); // Revert text color
        setBorderColor('primary.main'); // Revert border color
        setCopied(false);
      }, 3000);
    } catch (err) {
      console.error('Error copying text: ', err);
    }
    document.body.removeChild(textArea);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        marginBottom: 0,
        marginTop: 3,
        borderRadius: 1,
        backgroundColor: 'transparent',
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        label={name}
        value={displayText}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleCopy} edge="end">
                {copied ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <ContentCopyIcon color="primary" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          maxWidth: '100%',
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'transparent',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: borderColor, // Dynamic border color
              borderWidth: '1px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: borderColor, // Use dynamic border color on hover
              borderWidth: '1px',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: borderColor, // Use dynamic border color when focused
              borderWidth: '1px',
            },
            transition: 'none',
          },
          '& .MuiInputBase-input': {
            cursor: 'default',
            fontSize: '0.8rem',
            color: textColor, // Use dynamic text color
          },
        }}
        focused
      />
    </Box>
  );
}

export default CopyableField;
