import React from 'react';
import { Card, CardContent, Typography, Paper, Divider } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';

function RefundPolicyCard() {
  // Define the refund policies in Bulgarian
  const policies = [
    {
      text: 'Трябва да отмените резервацията си поне 30 дни преди настаняването, за да получите пълно възстановяване на сумата.',
    },
    {
      text: 'Можете да получите пълно възстановяване на сумата, ако отмените резервацията си в рамките на 24 часа след направата й, при условие че отмяната се случи поне 7 дни преди настаняването.',
    },
    {
      text: 'Ако отмените резервацията си между 7 и 30 дни преди настаняването, ще бъдете таксувани 50% за всички нощувки.',
    },
    {
      text: 'Ако отмените резервацията си по-малко от 7 дни преди настаняването, ще бъдете таксувани 100% за всички нощувки.',
    },
  ];

  return (
    <Card sx={{ width: '90%', maxWidth: 500, margin: '40px auto', padding: '10px', borderRadius: 2 }}>
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          Политика за отмяна на резервация
        </Typography>
        <Divider sx={{ marginY: 2, backgroundColor: 'primary.main', height: 2 }} />

        {policies.map((policy, index) => (
          <Paper key={index} elevation={3} sx={{ padding: 2, marginBottom: 3 }}>
            <Typography variant="body2" sx={{ lineHeight: 1.2, fontSize: '0.9em' }}>
              {policy.text}
            </Typography>
          </Paper>
        ))}

        <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 1,
            marginTop: 2,
            border: '1px solid',
            borderColor: 'warning.main',
          }}
        >
          <InfoIcon fontSize="small" color="warning" />
          <Typography
            variant="caption" // Use smaller text
            sx={{ 
                color: 'warning.main',
                textAlign: 'center',
                flex: 1, 

               }}
          >
            С извършването на плащането автоматично се съгласявате с политиката за отмяна на резервации.
          </Typography>
          <ErrorIcon fontSize="small" color="warning" />
        </Paper>
      </CardContent>
    </Card>
  );
}

export default RefundPolicyCard;
