import { gradientColors } from './colors'; // Import the gradient color variables

export const componentsOverrides = {
  MuiButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: `linear-gradient(90deg, ${gradientColors.primaryStart}, ${gradientColors.primaryEnd})`, // Use the defined gradient colors
        color: theme.palette.background.paper, // Access the theme's background paper color
        border: 'none',
        boxShadow: 'none',
        transition: 'background 0.3s ease-in-out', // Smooth transition effect
        '&:hover': {
          background: `linear-gradient(270deg, ${gradientColors.primaryStart}, ${gradientColors.primaryEnd})`, // Rotate gradient 180 degrees on hover
        },
        '&:focus': {
          outline: 'none',
        },
        '&.Mui-disabled': {
          background: gradientColors.disabledBackground, // Greyed out color for disabled state
          color: gradientColors.disabledText, // Slightly darker text for disabled state
        },
      }),
    },
  },
  // Add more component overrides here if needed
};
