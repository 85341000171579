// palette.js

// Light mode color palette
const lightPalette = {
  primaryMain: '#1976d2',          // Main primary color
  secondaryMain: '#9c27b0',        // Main secondary color
  backgroundDefault: '#f7f7f7',    // Default background color
  backgroundPaper: '#ffffff',      // Background color for paper components
  textPrimary: '#292929',          // Primary text color
  warningLight: '#fff3cd',         // Light yellow background for warnings
  warningText: '#856404',          // Dark text color for warnings in light mode
};

// Dark mode color palette
const darkPalette = {
  primaryMain: '#90caf9',          // Main primary color
  secondaryMain: '#ce93d8',        // Main secondary color
  backgroundDefault: '#121212',    // Default background color for dark mode
  backgroundPaper: '#1e1e1e',      // Background color for paper components in dark mode
  textPrimary: '#ffffff',          // Primary text color
  warningDark: '#322b24',          // Darker background for warnings in dark mode
  warningText: '#ffcc80',          // Light text color for warnings in dark mode
};

export { lightPalette, darkPalette };
