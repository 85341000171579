import React from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'background.paper', // Paper background color from theme
        padding: '20px',
        paddingBottom: '40px',
        marginBottom: '20px',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: '600px', // Set a max width for the icon row
        }}
      >
        {/* Phone Section */}
        <Box sx={{ width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Link href="tel:0897923140" color="inherit" underline="none">
            <IconButton
              color="primary"
              sx={{
                '&:hover': {
                  color: 'success.main',
                },
                transition: 'color 0.3s ease',
              }}
            >
              <PhoneIcon sx={{ fontSize: 24 }} /> {/* Decreased icon size */}
            </IconButton>
          </Link>
          <Typography variant="body2" color="textPrimary">
            <strong>обадете се</strong>
          </Typography>
        </Box>

        {/* Email Section */}
        <Box sx={{ width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Link href="mailto:bozheime@gmail.com" color="inherit" underline="none">
            <IconButton
              color="primary"
              sx={{
                '&:hover': {
                  color: 'success.main',
                },
                transition: 'color 0.3s ease',
              }}
            >
              <EmailIcon sx={{ fontSize: 24 }} /> {/* Decreased icon size */}
            </IconButton>
          </Link>
          <Typography variant="body2" color="textPrimary">
            <strong>пишете ни</strong>
          </Typography>
        </Box>

        {/* Facebook Section */}
        <Box sx={{ width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Link
            href="https://www.facebook.com/bozheime"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
            underline="none"
          >
            <IconButton
              color="primary"
              sx={{
                '&:hover': {
                  color: 'success.main',
                },
                transition: 'color 0.3s ease',
              }}
            >
              <FacebookIcon sx={{ fontSize: 24 }} /> {/* Decreased icon size */}
            </IconButton>
          </Link>
          <Typography variant="body2" color="textPrimary">
            <strong>последвайте ни</strong>
          </Typography>
        </Box>
      </Box>

      {/* Centered Text Below Icons */}
      <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
        <Typography variant="body2" color="textPrimary">
          <strong>комплекс "Боже Име"</strong>
        </Typography>
        <Typography variant="body2" color="textPrimary" sx={{fontSize: '0.7em'}}>
          всички права запазени ©
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
