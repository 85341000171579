
import React, { useEffect } from 'react';
import { Modal, Backdrop, Fade, Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles'; // Import useTheme to access the primary color

function CardPaymentInfoModal({ open, handleClose }) {
  const theme = useTheme(); // Use theme to access primary color

  useEffect(() => {
    const handleBackButton = () => {
      if (open) {
        handleClose();
        window.history.pushState(null, document.title);
      }
    };

    if (open) {
      window.history.pushState(null, document.title);
      window.addEventListener('popstate', handleBackButton);
    }

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [open, handleClose]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            maxWidth: '80vw',
            bgcolor: 'background.paper',
            border: `0px solid ${theme.palette.primary.main}`, // Set border to 1px and button blue color
            boxShadow: 24,
            p: 3,
            borderRadius: 1,
            maxHeight: '80vh',
            overflowY: 'auto',
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Инструкции за плащане с карта
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.85rem', marginBottom: 2 }}>
            <strong>Как да платите с карта:</strong>
            <ol>
              <li>Изберете опцията „Плащане с карта“ в онлайн магазина или приложението, което използвате.</li>
              <li>Въведете данните от картата си:</li>
              <ul>
                <li>
                  <strong>Номер на картата:</strong> Това е 16-цифреният номер на лицевата страна на картата ви.
                </li>
                <li>
                  <strong>Име на картодържателя:</strong> Това е името, което е изписано на картата (напр. „Иван Иванов“).
                </li>
                <li>
                  <strong>Дата на валидност:</strong> Намерете я на лицевата страна на картата, обикновено във формат MM/YY (например „12/24“ означава декември 2024 г.).
                </li>
                <li>
                  <strong>CVV/CVC код:</strong> Това са последните три цифри, разположени на гърба на картата ви до полето за подпис.
                </li>
              </ul>
              <li>Проверете въведените данни и натиснете „Потвърди“ или „Продължи“.</li>
              <li>Може да бъде изискана допълнителна автентикация чрез SMS код или мобилно приложение за сигурност на банката.</li>
            </ol>
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.85rem', marginBottom: 2 }}>
            <strong>Къде да намерите CVV/CVC и дата на валидност:</strong>
            <ul>
              <li>
                <strong>CVV/CVC код:</strong> Това са трите цифри на гърба на картата ви, които служат за допълнителна защита при онлайн плащания.
              </li>
              <li>
                <strong>Дата на валидност:</strong> Изписана е на лицевата страна на картата под номера на картата. Форматът обикновено е MM/YY.
              </li>
            </ul>
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
            Ако имате въпроси относно вашата карта или плащанията, свържете се с вашата банка за допълнителна помощ и информация.
          </Typography>

          <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 2 }}>
            Разбрах
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
}

export default CardPaymentInfoModal;