import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import BankDetails from '../components/BankDetails/BankDetails';
import OnlinePayment from '../components/OnlinePayment/OnlinePayment';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';

const shakeAnimation = {
  '@keyframes shake': {
    '0%': { transform: 'rotate(0deg) scale(1)' },
    '25%': { transform: 'rotate(5deg) scale(1.1)' },
    '50%': { transform: 'rotate(-5deg) scale(1.1)' },
    '75%': { transform: 'rotate(5deg) scale(1.1)' },
    '100%': { transform: 'rotate(0deg) scale(1)' },
  },
  animation: 'shake 0.5s ease infinite',
};

function PaymentTabs() {
  const [value, setValue] = useState(0);
  const [shake, setShake] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value !== 1) {
      const interval = setInterval(() => {
        setShake((prev) => !prev);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [value]);

  return (
    <Box sx={{ maxWidth: '600', width: '100%', marginTop: 0 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: '500',

            textAlign: 'center',
            marginBottom: -0,
          }}
        >
          ПРЕДПОЧИТАМ ДА ПЛАТЯ С
        </Typography>
      </Box>
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        sx={{
          display: 'flex',
          justifyContent: 'center',
          boxShadow: 1,
          backgroundColor: 'background.paper',
          marginTop: 0,
        }}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <Tab
          icon={<AssuredWorkloadIcon sx={{ color: value === 0 ? 'primary.main' : 'text.primary' }} />}
          iconPosition="start"
          label={
            <Typography sx={{ fontSize: '0.7rem', fontWeight: value === 0 ? 'bold' : 'normal', color: value === 0 ? 'primary.main' : 'text.primary' }}>
              Банков Превод
            </Typography>
          }
          sx={{ flex: 1, textAlign: 'center', maxWidth: '50%' }}
        />
        <Tab
          icon={<CreditScoreIcon sx={{ color: value === 1 ? 'primary.main' : 'text.primary', ...(value !== 1 && shake ? shakeAnimation : {}) }} />}
          iconPosition="start"
          label={
            <Typography sx={{ fontSize: '0.7rem', fontWeight: value === 1 ? 'bold' : 'normal', color: value === 1 ? 'primary.main' : 'text.primary' }}>
              онлайн с карта
            </Typography>
          }
          sx={{ flex: 1, textAlign: 'center', maxWidth: '50%' }}
        />
      </Tabs>
      <Box sx={{ padding: 0 }}>
        {value === 0 && <BankDetails />}
        {value === 1 && <OnlinePayment />}
      </Box>
    </Box>
  );
}

export default PaymentTabs;
