// InfoModal.js
import React, { useEffect } from 'react';
import { Modal, Backdrop, Fade, Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles'; // Import useTheme to access the primary color

// Function to get dynamic dates
const getDynamicDates = () => {
  const today = new Date();
  const arrivalDate = new Date(today);
  arrivalDate.setDate(today.getDate() + 10); // 10 days after today

  const departureDate = new Date(arrivalDate);
  departureDate.setDate(arrivalDate.getDate() + 5); // Example: 5 days stay

  // Format dates as dd.mm.yyyy
  const arrivalStr = arrivalDate.toLocaleDateString('bg-BG', { day: '2-digit', month: '2-digit', year: 'numeric' });
  const departureStr = departureDate.toLocaleDateString('bg-BG', { day: '2-digit', month: '2-digit', year: 'numeric' });

  return { arrivalStr, departureStr };
};

function InfoModal({ open, handleClose }) {
  const theme = useTheme(); // Use theme to access primary color
  const { arrivalStr, departureStr } = getDynamicDates(); // Get dynamic dates

  useEffect(() => {
    const handleBackButton = () => {
      if (open) {
        handleClose();
        window.history.pushState(null, document.title);
      }
    };

    if (open) {
      window.history.pushState(null, document.title);
      window.addEventListener('popstate', handleBackButton);
    }

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [open, handleClose]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            maxWidth: '80vw',
            bgcolor: 'background.paper',
            border: `0px solid ${theme.palette.primary.main}`, // Set border to 1px and button blue color
            boxShadow: 24,
            p: 3,
            borderRadius: 1,
            maxHeight: '80vh',
            overflowY: 'auto',
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Инструкции за банков превод
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.85rem', marginBottom: 2 }}>
            <strong>1. Превод чрез мобилно банково приложение:</strong>
            <ol>
              <li>Отворете мобилното приложение на вашата банка и влезте в акаунта си.</li>
              <li>Намерете опцията „Преводи“ или „Платежни нареждания“ в менюто.</li>
              <li>
                Въведете необходимите данни:
                <ul>
                  <li><strong>IBAN</strong> на получателя (банковата сметка на бенефициента).</li>
                  <li><strong>Бенефициент</strong> (името на получателя).</li>
                  <li><strong>Сума</strong> за превода.</li>
                  <li>
                    <strong>Основание за плащане</strong>: въведете имената на резервацията и датите на пристигане и напускане (например „Резервация Иван Иванов, {arrivalStr} - {departureStr}“).
                  </li>
                </ul>
              </li>
              <li>Потвърдете данните и натиснете „Изпрати“ или „Потвърди“.</li>
              <li>Може да се изисква допълнително потвърждение с парола или SMS код.</li>
            </ol>
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.85rem', marginBottom: 2 }}>
            <strong>2. Превод при посещение на банков офис:</strong>
            <ol>
              <li>Посетете офис на вашата банка с лична карта.</li>
              <li>Информирайте служителя, че искате да направите банков превод.</li>
              <li>
                Предоставете следните данни:
                <ul>
                  <li><strong>IBAN</strong> на получателя.</li>
                  <li><strong>Бенефициент</strong> (името на получателя).</li>
                  <li><strong>BIC</strong> на банката на получателя (ако е необходимо).</li>
                  <li>
                    <strong>Сума</strong> и <strong>основание за превода</strong>: посочете имената на резервацията и датите на пристигане и напускане (например „Резервация Иван Иванов, {arrivalStr} - {departureStr}“).
                  </li>
                </ul>
              </li>
              <li>Служителят ще обработи превода и ще ви даде разписка за потвърждение.</li>
            </ol>
          </Typography>

          <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
            <strong>3. Превод в офис на EasyPay:</strong>
            <ol>
              <li>Посетете офис на EasyPay с лична карта.</li>
              <li>Кажете на служителя, че искате да направите банков превод.</li>
              <li>
                Предоставете следните данни:
                <ul>
                  <li><strong>IBAN</strong> на получателя.</li>
                  <li><strong>Бенефициент</strong> (името на получателя).</li>
                  <li>
                    <strong>Сума</strong> и <strong>основание за превода</strong>: въведете имената на резервацията и датите на пристигане и напускане (например „Резервация Иван Иванов, {arrivalStr} - {departureStr}“).
                  </li>
                </ul>
              </li>
              <li>Платете сумата в брой и таксата за превода.</li>
              <li>Получете разписка като потвърждение на извършения превод.</li>
            </ol>
          </Typography>

          <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 2 }}>
            Разбрах
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
}

export default InfoModal;
