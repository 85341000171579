import React, { useMemo, useState } from 'react';
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { lightTheme, darkTheme } from './theme'; // Now importing from index.js in the theme folder
import PaymentTabs from './PaymentTabs/PaymentTabs'; // Adjust the path as necessary
import RefundPolicyCard from './components/RefundPolicy/RefundPolicy'; // Adjust the path as necessary
import Footer from './components/Footer/Footer'; // Adjust the path as necessary
import Header from './components/Header/Header'; // Adjust the path as necessary
import PrepaymentInfo from './components/PrepaymentInfo/PrepaymentInfo'; // Adjust the path as necessary

function App() {
  const [showPrepaymentInfo, setShowPrepaymentInfo] = useState(true); // State to manage visibility

  // Check if the system is set to dark mode
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Memoize the theme to avoid unnecessary re-renders
  const theme = useMemo(() => (prefersDarkMode ? darkTheme : lightTheme), [prefersDarkMode]);

  const handlePrepaymentInfoClose = () => {
    setShowPrepaymentInfo(false); // Hide PrepaymentInfo on button click
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Applies the theme's default styles */}
      <Header />
      {showPrepaymentInfo ? (
        <PrepaymentInfo onClose={handlePrepaymentInfoClose} />
      ) : (
        <>
          <PaymentTabs /> {/* Main payment tabs component */}
          <RefundPolicyCard /> {/* Refund policy card */}
          <Footer /> 

        </>
      )}
    </ThemeProvider>
  );
}

export default App;
