// darkTheme.js
import { createTheme } from '@mui/material/styles';
import { darkPalette } from './palette';
import { componentsOverrides } from './componentsOverrides'; // Import component overrides

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: darkPalette.primaryMain,
      dark: darkPalette.primaryDark, // Ensure you define dark
      light: darkPalette.primaryLight, // Ensure you define light
      contrastText: '#ffffff', // Or use your dark mode contrast text color
    },
    secondary: {
      main: darkPalette.secondaryMain,
    },
    background: {
      default: darkPalette.backgroundDefault,
      paper: darkPalette.backgroundPaper,
    },
    text: {
      primary: darkPalette.textPrimary,
    },
  },
  components: componentsOverrides, // Use shared component overrides
});

export default darkTheme;
